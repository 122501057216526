<template>
  <div>
    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <span class="d-none d-sm-inline">{{ $t('arl_codes.object.services') }}</span>
        </div>
      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          v-if="elementData"
          id="refElementsListTable"
          ref="refElementsListTable"
          :empty-text="$t(resourcesElementName + '.search.not_found')"
          :fields="tableColumns"
          :items="elementData"
          class="position-relative"
          primary-key="id"
          responsive
          show-empty
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'

export default {
  components: {
    BTable, BFormTextarea, DatePicker, BFormCheckbox, BCard, BButton, BRow, BCol, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      resourcesElementName: '',
    }
  },
  mounted() {
    this.resourcesElementName = 'services'
    this.tableColumns = [
      { key: 'cups', label: this.$t(`${this.resourcesElementName}.object.cups_cum`), sortable: true },
      { key: 'cups_name', label: this.$t('arl_codes.object.services'), sortable: true },
    ]
  },
}
</script>
<style>

</style>
