import axios from '@axios'
import handlerDataSearch from '@/handlers/handlerDataSearch'

export default {
  namespaced: true,
  state: {
    searchData: null,
    currentPage: 1,
    abortController: null,
  },
  getters: {
  },
  mutations: {
    setSearchData(state, data) {
      state.searchData = data
    },
    setCurrentPage(state, data) {
      state.currentPage = data
    },
    SET_CONTROLLER(state, controller) {
      state.abortController = controller
    },
    CLEAR_CONTROLLER(state) {
      state.abortController = null
    },
  },
  actions: {
    fetch_elements({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/arl_codes_search', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
  },
}
